<template>
	<div class="hand_card">
		<el-form :inline="true" ref="form" :model="form" label-width="auto" size="mini">
			<el-form-item label="店铺名称">
				<el-input v-model="form.name" placeholder="请输入店铺名称"></el-input>
			</el-form-item>
			<el-form-item label="商品名称">
				<el-input v-model="form.name" placeholder="请输入商品名称"></el-input>
			</el-form-item>
			<el-form-item label="商品分类">
				<el-select v-model="form.region" placeholder="商品分类">
				<el-option label="区域一" value="shanghai"></el-option>
				<el-option label="区域二" value="beijing"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="审核状态">
				<el-select v-model="form.region" placeholder="审核状态">
				<el-option label="区域一" value="shanghai"></el-option>
				<el-option label="区域二" value="beijing"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="排期日期">
				<el-date-picker
					v-model="form.value1"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
					</el-date-picker>
			</el-form-item>
			<el-form-item label="已排期商品">
				<el-checkbox v-model="checked"></el-checkbox>
			</el-form-item>
			<el-form-item>
				<el-button @click="form = {}">重置</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="primary">查询</el-button>
			</el-form-item>
			<el-form-item>
				<el-button>导出</el-button>
			</el-form-item>
			<el-form-item v-if="activeName === '待审核' || activeName === '待复审' || activeName === '待终审'">
				<el-button>批量审核</el-button>
			</el-form-item>
		</el-form>
		<div class="table_title flex_r bg_table mb10">
			<span>审核状态</span>
			<span>商品信息</span>
			<span>价格</span>
			<span>优惠</span>
			<span>福利</span>
			<span>试样记录</span>
			<span>操作</span>
		</div>
		<div class="br_1_eee">
			<div class="item_title flex_r f_ai_c f_jc_b tc bg_table pl20 pr20 fs12">
				<div>
					<el-checkbox></el-checkbox>
					<span class="pl20 pr20">意向排期：</span>
					<span>商品来源：商家报名</span>
				</div>
				<div>
					<img class="mr10" width="16" height="16" src="//img.alicdn.com/imgextra//cb/34/TB1nMIPiqL7gK0jSZFBwu0ZZpXa.png" alt="">
					<span>INNSO悦赏旗舰店</span>
				</div>
				<div class="flex_c f_jc_c f_ai_c" @click="flagHandle">
					<i class="el-icon-s-flag fs16 cr_eee"></i>
					<span>标记</span>
				</div>
			</div>
			<ul class="item_ul flex_r f_ai_c f_jc_b fs12">
				<li class="br_r_1_eee flex_c f_jc_b">
					<el-select class="">
						<el-option label="选项1" value="1"></el-option>
					</el-select>
					<div>
						<span>排期：</span>
						<el-date-picker
							style="width:150px"
							class="mt10"
							v-model="value1"
							type="date"
							placeholder="选择排期">
						</el-date-picker>
					</div>
					<span>活动：</span>
				</li>
				<li class="br_r_1_eee flex_r">
					<el-popover
						trigger="hover">
						<img height="300" width="300" src="https://filenew.lxshuju.com/000000/zanwutupian.png" alt="">
						<img slot="reference" height="80" width="80" src="https://filenew.lxshuju.com/000000/zanwutupian.png" alt="">
					</el-popover>
					<div class="pl10 pr10">
						<el-link class="lh_12 ellipsis_2" type="primary">零限提臀裤健身高腰提臀显瘦蜜桃翘臀塑形瑜伽紧身裤女外穿弹力裤</el-link>
						<el-select class="mt10">
							<el-option label="选项1" value="1"></el-option>
						</el-select>
						<div class="pt10">
							<span>规格：</span>
							<span class="cr_999 ellipsis_2 w_50">XS1111111111</span>
						</div>
					</div>
				</li>
				<li class="br_r_1_eee flex_c f_jc_b">
					<el-input placeholder="请输入直播价" disabled v-model="input1">
						<template slot="prepend">直播价</template>
					</el-input>
					<el-input placeholder="请输入在售价" disabled v-model="input1">
						<template slot="prepend">在售价</template>
					</el-input>
					<el-input placeholder="请输入在售价" v-model="input1">
						<template slot="prepend">历史最低价</template>
					</el-input>
				</li>
				<li class="br_r_1_eee">
					<el-input :rows="6" type="textarea" placeholder=""></el-input>
				</li>
				<li class="br_r_1_eee flex_c f_jc_b">
					<el-input :rows="3" type="textarea" placeholder="赠品："></el-input>
					<el-input :rows="3" type="textarea" placeholder="抽奖："></el-input>
				</li>
				<li class="br_r_1_eee">
					<el-input :rows="6" type="textarea" placeholder=""></el-input>
				</li>
				<li class="br_r_1_eee flex_c f_jc_a f_ai_c">
					<span class="cur_p" @click="$refs.dialogDataRef.handleOpen()">查看数据</span>
					<router-link tag="a" target="_blank" :to="{path:'/goodsDetail'}">报名详情</router-link>
					<router-link tag="a" target="_blank" :to="{path:'/handCardDetail'}">查看手卡</router-link>
				</li>
			</ul>
			<ul v-if="toggle" class="br_t_1_eee pl40 pr40 pt10 tc">
				<li class="toggle flex_r f_jc_b lh12">
					<span>合同状态</span>
					<span>服务费</span>
					<span>保证金</span>
					<span>佣金链接</span>
					<span>样品</span>
				</li>
				<li  class="toggle flex_r f_jc_b">
					<div @click="sponsorHandle(1)">
						<span class="pr10">带发起</span>
						<el-link class="" type="primary">发起</el-link>
					</div>
					<div @click="sponsorHandle(2)">
						<span class="pr10">带发起</span>
						<el-link class="" type="primary">发起</el-link>
					</div>
					<div @click="sponsorHandle(3)">
						<span class="pr10">带发起</span>
						<el-link class="" type="primary">发起</el-link>
					</div>
					<div @click="sponsorHandle(4)">
						<span class="pr10">待提交</span>
						<!-- <el-link class="" type="primary">发起</el-link> -->
					</div>
					<div @click="sponsorHandle(5)">
						<span class="pr10">待发起寄送</span>
						<el-link class="" type="primary">发起</el-link>
					</div>
				</li>
			</ul>
			<div class="item_title bg_fff flex_r f_ai_c f_jc_c tc pl20 pr20 fs12 br_t_1_eee br_b_1_eee cr_999 cur_p">
				<div @click="toggle = !toggle">
					<i :class="toggle ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
					<span>{{toggle ? '收起' : '展开'}}</span>
				</div>
			</div>
		</div>
		<el-pagination
			class="tr"
			:small="true"
			:current-page.sync="currentPage"
			:page-size="pageSize"
			:total="total">
		</el-pagination>
		<e-dialog :title="dialogTitle" ref="dialogRef">
			<el-form v-if="flag" label-width="auto">
				<el-form-item label="标记">
					<el-radio-group v-model="radio">
						<el-radio :label="3"><i class="el-icon-s-flag fs18 cr_eee"></i></el-radio>
						<el-radio :label="3"><i class="el-icon-s-flag fs18 cr_ff0"></i></el-radio>
						<el-radio :label="6"><i class="el-icon-s-flag fs18 cr_f18"></i></el-radio>
						<el-radio :label="9"><i class="el-icon-s-flag fs18 cr_18a"></i></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="标记信息">
					<el-input type="textarea"></el-input>
				</el-form-item>
			</el-form>
			<e-form v-else :formConfList="formConfListItem" />
		</e-dialog>
		<e-dialog width="1000px" title="查看数据" ref="dialogDataRef">
			<Empty />
		</e-dialog>
	</div>
</template>

<script>
import {formConfList} from '@data/handCard'
export default {
	name: 'HandCard',
	data() {
		return {
			form: {},
			formConfList,
			formConfListItem: [],
			dataOrigin: {
				data: [
					{
						account1: '一条网络科技有限公司',
						value: 3,
						tag: '品牌方'
					}
				]
			},
			currentPage: 1,
			pageSize: 10,
			total: 0,
			toggle: false,
			dialogTitle: '',
			flag: false
		}
	},
	methods: {
		dialogOpen() {
			this.$refs.dialogRef.handleOpen();
		},
		flagHandle() {
			this.flag = true;
			this.dialogTitle = '标记';
			this.dialogOpen();
		},
		sponsorHandle(val) {
			switch(val) {
				case 1:
					this.dialogTitle = '通知商家签约';
					break
				case 2:
					this.dialogTitle = '新增服务费';
					break
				case 3:
					this.dialogTitle = '新增保证金';
					break
				case 4:
					this.dialogTitle = '佣金链接';
					break
				case 5:
					this.dialogTitle = '通知寄样';
					break
			}
			this.flag = false;
			this.formConfListItem = this.formConfList[val]; 
			this.dialogOpen();
		}
	}
}
</script>

<style lang="scss" scoped>
.w140 {
	width: 140px;	
}
.table_title {
	span {
		line-height: 40px;
		width: 15%;
		text-align: center;
		&:nth-child(2) {
			width: 22%;
		}
		&:nth-child(3) {
			width: 28%;
		}
	}
}
.item_title {
	line-height: 30px;
}
.item_ul {
	li {
		width: 15%;
		padding: 10px;
		height: 120px;
		&:nth-child(2) {
			width: 22%;
		}
		&:nth-child(3) {
			width: 28%;
		}
	}
}
.toggle {
	span,
	div {
		width: 20%;
		line-height: 2;
	}
	&:nth-child(1) {
		background: #fff9f8;
	}
}
</style>